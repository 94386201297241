
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'

// Components
import SLoader from '@/common/components/SLoader/index.vue'
import UploadedFile from '@/common/components/UploadedFile/index.vue'
import FTLBidHeader from '@/modules/FTLBidModule/components/FTLBidHeader/index.vue'
import SButton from '@/common/components/SButton/index.vue'

// Realisations
import { FTLRealisation } from '@/core/realisations/FTL.realisation'

// Types
import { FullFTLBid, FTLBidWaypointT } from '@/core/types/FTL.types'
import { useTitle } from 'vue-composable'

import useNotifications from '@/common/composable/useNotifications'

export default defineComponent({
    components: {
        's-loader': SLoader,
        UploadedFile,
        'ftl-bid-header': FTLBidHeader,
        's-button': SButton,
    },
    setup() {
        useTitle('FTL заявка')

        const is_print_invoices_loading = ref(false)

        const notifications = useNotifications()
        const FTL = new FTLRealisation()

        const route = useRoute()
        const bid_id = Number(route.params.bid_id)

        const state = reactive({
            bid: null as null | FullFTLBid,
            is_loading: false,
        })

        const loadBid = async () => {
            try {
                state.is_loading = true
                const response = await FTL.getBid(bid_id)
                state.bid = response.data
            } catch (error) {
                console.error(error)
            } finally {
                state.is_loading = false
            }
        }

        const getTemplate = (waypoint: FTLBidWaypointT) => {
            return waypoint.receiver_template || waypoint.sender_template
        }

        const saveFile = (content: Blob | File, file_name: string) => {
            const download_url = window.URL.createObjectURL(content)

            const link = document.createElement('a')
            link.href = download_url
            link.setAttribute('download', file_name)
            document.body.appendChild(link)
            link.click()
            link.remove()
        }

        const printFtlBidWaypointInvoice = async (waypoint_index: number) => {
            if (is_print_invoices_loading.value) return

            try {
                is_print_invoices_loading.value = true

                const response = await FTL.printFtlBidWaypointInvoice(bid_id, waypoint_index)
                saveFile(response, `Накладная FTL заявки №${bid_id} пункт ${waypoint_index}.pdf`)
            } catch (error) {
                notifications.failure({
                    group: 'create-module',
                    type: 'error',
                    title: 'При распечатке накладной заявки ошибка',
                    text: 'Пожалуйста, попробуйте ещё раз',
                })
            } finally {
                is_print_invoices_loading.value = false
            }
        }

        //

        const isInvoiceFiles = computed(() =>
            Boolean(state.bid && state.bid.invoice_files && state.bid.invoice_files.length)
        )
        const isCertificate = computed(() =>
            Boolean(
                state.bid &&
                    state.bid.certificate_of_safety_files &&
                    state.bid.certificate_of_safety_files.length
            )
        )

        //

        onBeforeMount(loadBid)

        //

        return {
            state,
            bid_id,
            getTemplate,
            //
            isInvoiceFiles,
            isCertificate,
            is_print_invoices_loading,
            printFtlBidWaypointInvoice
        }
    },
})
