
import { defineComponent, ref } from 'vue'

import SIconButton from '@/common/components/SIconButton/index.vue'
import iDownload from '@/assets/icons/Download.svg'
import useNotifications from '@/common/composable/useNotifications'

import { FTLRealisation } from '@/core/realisations/FTL.realisation'

export default defineComponent({
    components: {
        's-icon-button': SIconButton,
        'icon-download': iDownload,
    },
    props: {
        bidId: {
            type: Number,
            default: null
        }
    },
    setup(props) {
        const ftl = new FTLRealisation()
        const notifications = useNotifications()

        const is_print_invoices_loading = ref(false)

        const saveFile = (content: Blob | File, file_name: string) => {
            const download_url = window.URL.createObjectURL(content)

            const link = document.createElement('a')
            link.href = download_url
            link.setAttribute('download', file_name)
            document.body.appendChild(link)
            link.click()
            link.remove()
        }

        const printFtlBidInvoices = async () => {
            if (is_print_invoices_loading.value) return

            try {
                is_print_invoices_loading.value = true

                const response = await ftl.printFtlBidInvoices(props.bidId)
                saveFile(response, `Накладные FTL заявки ${props.bidId}.pdf`)
            } catch (error) {
                notifications.failure({
                    group: 'create-module',
                    type: 'error',
                    title: 'При распечатке накладных заявки ошибка',
                    text: 'Пожалуйста, попробуйте ещё раз',
                })
            } finally {
                is_print_invoices_loading.value = false
            }
        }

        return {
            is_print_invoices_loading,
            printFtlBidInvoices
        }
    }
})
