
import { defineComponent } from 'vue'

import SBreadCrumbs from '@/common/components/SBreadCrumbs/index.vue'
import iArrowLeft from '@/assets/icons/ArrowLeft.svg'
import FtlBidHeaderActions from '@/modules/FTLBidModule/components/FTLBidHeaderActions/index.vue'

export default defineComponent({
    props: {
        bidId: {
            type: Number,
            default: null
        }
    },
    components: {
        's-bread-crumbs': SBreadCrumbs,
        'icon-arrow-left': iArrowLeft,
        'ftl-bid-header-actions': FtlBidHeaderActions,
    },
    setup(props) {
        const breadcrumbsLinks = [
            {
                id: 1,
                name: 'FTL заявки',
                path: '/history',
            },
            {
                id: 2,
                name: props.bidId,
                path: `/ftl/bid/${props.bidId}`,
            },
        ]

        return {
            breadcrumbsLinks
        }
    }
})
