import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "FTLBidHeader" }
const _hoisted_2 = { class: "FTLBidHeader__information" }
const _hoisted_3 = { class: "information__footer" }
const _hoisted_4 = { class: "information__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_s_bread_crumbs = _resolveComponent("s-bread-crumbs")!
  const _component_icon_arrow_left = _resolveComponent("icon-arrow-left")!
  const _component_ftl_bid_header_actions = _resolveComponent("ftl-bid-header-actions")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_s_bread_crumbs, { links: _ctx.breadcrumbsLinks }, null, 8, ["links"]),
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_icon_arrow_left, {
          class: "information__back",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.go(-1)))
        }),
        _createVNode("span", _hoisted_4, _toDisplayString(_ctx.bidId), 1)
      ])
    ]),
    _createVNode(_component_ftl_bid_header_actions, { "bid-id": _ctx.bidId }, null, 8, ["bid-id"])
  ]))
}